import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { API_URL } from "../../configs/siteConfig";
import Auth_Actions from "../../redux/auth/actions";
import BrandsLogo from "../components/Brands/BrandsLogo";
import SliderHome from "../components/Slider/SliderHome";
import "./home.css";
function Home() {
  const {shortcode} = useParams();
  
  

  const dispatch = useDispatch();
  const Auth = useSelector((state) => state.Auth);


  useEffect(() => {

  const queryParameters = new URLSearchParams(window.location.search);
  /*
  const r = queryParameters.get("r");
    if(r !== null)
    {    
      var url = API_URL+ 'api/Link/?shortCode='+r;
      fetch(url)
          .then((response) => response.json())
          .then((data) => {         
              //dispatch(Auth_Actions.auction(data.ShortCode)); 
              setTimeout(() => {window.location.href = data.Link.replace(/"|'/g, '');},500);
          });
    }    
    */
    if (shortcode) {
      dispatch(Auth_Actions.auction( {ShortCode: shortcode,afterAction: () => {
        console.log('home finish')
        if (Auth.auctionData) {
        
          document.documentElement.style.setProperty('--accentColor', Auth.auctionData.AccentColor);    
        }
      }} ));
    }  
  }, [dispatch]);

  //const loader = document.querySelector('.global-loader');

  useEffect(() => {
    //setTimeout(() => loader.classList.add('pageloaded'), 0)
  });

  return (
    <div>
      
      <SliderHome Raised={Auth.auctionData?.AmountRaised} Goal={Auth.auctionData?.Goal} ShowFundsRaised={Auth.auctionData?.ShowFunds} />
           

      <BrandsLogo Imgs={Auth.auctionData?.SponsorImages} />

      <div className="container py-4">
        {/* <hr /> */}
        <section className="bg-default text-center   px-sm-0">
          <div className="text-center row justify-content-lg-center">
            <div className="col-lg-10 col-xl-8 text welcome-text">
              <h4 className="heading-title">{Auth.auctionData?.Name?Auth.auctionData?.Name:"Welcome to Charity Event 2022"}</h4>
              <p class="dateText">Start: {Auth.auctionData?.StartDateTimeDisplay ? Auth.auctionData?.StartDateTimeDisplay : ""}</p>
              <p class="dateText">End: {Auth.auctionData?.EndDateTimeDisplay?Auth.auctionData?.EndDateTimeDisplay:""}</p>
              
              <div class="welcomeText" dangerouslySetInnerHTML={{__html:Auth.auctionData?.WelcomeMessage}}/>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
